.rbc-agenda-date-cell, .rbc-agenda-time-cell {
    white-space: nowrap;
    background-color: white;
    border: 1px solid gray; 
}

.Industry {
    background-color: #B2CEFE; 
}

.Startups {
    background-color: #F8F3B0; 
}

.Opportunities {
    background-color: #BDE4DC;
}

.Academia {
    background-color: #F9DED7;
}

.Funding {
    background-color: #F6CEDE
}

.Other {
    background-color: #E2BEF2;
}